import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import StarRating from "react-svg-star-rating"
import { Link } from "gatsby"
import styled from "styled-components"
import ReviewContainer from "../components/styles/ReviewContainer"
import MainButton from "../components/styles/MainButton"
import RelatedBikes from "../components/RelatedBikes"

class BikeReview extends React.Component {
  render() {
    const affiliateLink = "https://www.roguefitness.com/rogue-echo-bike?a_aid=5e7fb64738ac3"
    const linkText = "$775 from Rogue Fitness (On Sale Now)"

    return (
      <Layout>
        <SEO
          title="Rogue Echo Bike In-Depth Review 2020"
          description="This belt-driven, overbuilt unit provides a rock solid foundation producing a smooth, consistent ride, while a high-contrast, battery-powered LCD console allows athletes to track intervals, distance, calories burned, heart rate, and more. Get yours today!"
        />
        <ReviewContainer>
          <div className="r-post">
            <div>
              <aside className="r-article-toc">
                <div className="product-name">
                  <p className="article-type">In-Depth Review</p>
                  <h2>Rogue Echo Bike</h2>
                  <p>Updated Dec 3rd, 2020</p>
                </div>
                <div>
                  <div className="r-article-toc--btn">Table of Contents</div>
                </div>
                <div className="r-article-toc--contents">
                  <ul>
                    <li>
                      <span onClick={() => scrollTo("#n0")}>Review</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n1")}>
                        Pros and Cons of Rogue Echo Bike
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n2")}>
                        What sets the Rogue Echo Bike Apart?
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n3")}>
                        Rogue Echo Bike Comparison
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n4")}>
                        Rogue Echo Bike Rating
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n5")}>Where to buy</span>
                    </li>
                  </ul>
                </div>
                <div className="r-article-toc--cta">
                  <MainButton
                    href={affiliateLink}
                    target="_blank"
                  >
                    {linkText}
                  </MainButton>
                </div>
              </aside>
            </div>
            <div className="r-post-content">
              <div className="r-post-container">

                <div className="r-product-box">
                  <div className="product-box-brand">Rogue Echo Bike</div>
                  <div className="product-left">
                    <img
                      src="/images/rogue-echo/echo-bike-side.png"
                      alt="Rogue Echo Bike"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Rogue Echo Bike
                    </a>
                    <p>
                      The Rogue Echo Bike combines heavy-duty steel, precision
                      engineering, and convenient customization to forge a
                      stronger, sturdier fan bike.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
                <p className="r-post-intro">
                  The <span className="brand-name">Rogue Echo Bike</span>{" "}
                  combines heavy-duty steel, precision engineering, and
                  convenient customization to forge a stronger, sturdier fan
                  bike.
                </p>

                <span id="n0"></span>
                <h2>Review</h2>
                <p>
                  The Rogue Echo Bike is undoubtedly one of the best Air Bikes
                  available to buy currently, and it just so happens that it's
                  one of the least expensive among the top competitors in the
                  Air Bike space. The Echo bike is built like an absolute tank
                  and can withstand many types of intense workouts you’ll be
                  throwing at it for many years to come.
                </p>
                <p>
                  The Echo Bike is equipped with a precision steel blade
                  belt-driven system, which closely resembles the belts used in
                  automobiles. Thats a big plus, since these belts are tough,
                  quiet, and will be able to withstand anything you plan on
                  throwing at it.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-quality.png"
                  alt="Rogue Echo Bike Belt-Driven System"
                />
                <p>
                  The Rogue Echo Bike is fairly quiet compared to the
                  competition. You’ll hear the wind driving through the big
                  wheel when hitting the machine hard. Older Schwinn Airdyne and
                  the Assault AirBike typically produce a bit more noise due to
                  some of the equipment construction utilizing mostly metal
                  frames unlike the Rogue Echo Bike which is made of nearly all
                  metal. You definitely won’t hear the same creaking noises like
                  the other machines give off.
                </p>
                <p>
                  The Echo Bike is definitely the beefest of the Air Bike bunch,
                  weighing in at a whopping 127LBS with a 350LB weight limit. To
                  compare, the Assault AirBike weighs 98LBS. The additional
                  added weight however won’t make it difficult to maneuver and
                  take it around from one spot to another. The added weight adds
                  to the overall sturdiness of the bike, keeping it from
                  wobbling like some other Air Bikes on the market. Its
                  reinforced steel construction also gives it the rigidity and
                  balance to help eliminate sways and bumps during even max
                  output bursts, and the fan’s steady response delivers a
                  uniquely consistent, smooth riding experience.
                </p>
                <p>
                  The Rogue Echo Bike also comes equipped with a mounted 6” LCD
                  console and features a crystal clear, high contrast display.
                  You’ll be able to select or create from a wide range of modes,
                  including intervals, Target (Time, Distance, or Calories), and
                  Heart Rate Tracking (requires heart rate transmitter to send
                  data). The LCD console runs on just two AA batteries, which
                  are included. The console also has some quick select buttons
                  on the right side of the monitor which makes it easy to see
                  during use.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-lcd.png"
                  alt="Rogue Echo Bike LCD Console"
                />
                <p>
                  Overall, we’re big fans of the Rogue Echo Bike. It’s built
                  extremely tough, requires nearly zero maintenance, it’s quiet
                  (for an Air Bike), and it delivers a heart-pounding workout.
                </p>
                <span id="n1"></span>
                <h2>Pros and Cons of Rogue Echo Bike</h2>
                <h3>Positives:</h3>
                <ul className="r-content-list-positives">
                  <li>
                    No wobbling. Its reinforced steel construction gives it the
                    rigidity and balance to help eliminate sways and bumps
                    during even max output bursts
                  </li>
                  <li>
                    A quiet, belt-driven steel fan blade produces a smooth,
                    consistent ride.
                  </li>
                  <li>
                    Overbuilt to last. The Rogue Echo Bike is built almost
                    entirely out of heavy-duty steel and requires very little
                    maintenance.
                  </li>
                  <li>
                    Easy to assemble. Assembly is required to set-up the Echo
                    Bike, but all necessary tools and hardware are included
                    standard when you purchase.
                  </li>
                  <li>
                    Portability. There are a set of 1" wide polyurethane wheels
                    at the front of the bike, allowing it to be rolled around
                    the gym or stored out of the way with ease.
                  </li>
                </ul>
                <h3>Negatives:</h3>
                <ul className="r-content-list-negatives">
                  <li>
                    The LCD display console is not backlit. That means it’s hard
                    to see and use in the dark.
                  </li>
                  <li>Not Bluetooth compatible.</li>
                  <li>
                    Not a great option for shorter people. No petal and arm
                    adjustments make it more difficut for shorter people to
                    benefit from the Rogue echo bike.
                  </li>
                </ul>
                <span id="n2"></span>
                <h2>What sets the Rogue Echo Bike Apart?</h2>
                <h3>Echo Bike Features</h3>
                <h4>1. MAX STABILITY & A SMOOTHER RIDE</h4>
                <p>
                  Weighing in at 127LB, 350LB weight limit, the Echo Bike is not
                  the wobbling breed of exercise bike. Its reinforced steel
                  construction gives it the rigidity and balance to help
                  eliminate sways and bumps during even max output bursts, and
                  the fan’s steady response delivers a uniquely consistent,
                  smooth riding experience. For added stability, we’ve included
                  rubber leveling feet under each base tube. These feet adapt to
                  your flooring and provide added “stick,” helping to level the
                  bike even if it’s not on a level surface.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-smooth-ride.png"
                  alt="Rogue Echo Bike Smooth Ride"
                />

                <h4>2. LCD CONSOLE</h4>
                <p>
                  The mounted LCD console measures 6.375" x 6.375" and features
                  a crystal clear, high contrast display. Athletes can select or
                  create a wide range of modes, including Intervals (20/10,
                  10/20 or a custom Work/Rest interval), Target (choose a goal
                  Time, Distance or Calories and the console will count down
                  based on your selection), and Heart Rate Tracking (please note
                  that a heart rate transmitter is required to send data, but is
                  not included). The console runs on two AA batteries, which are
                  included.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-lcd.png"
                  alt="Rogue Echo Bike LCD"
                />

                <h4>3. QUALITY CONSTRUCTION</h4>
                <p>
                  The Echo Bike is built with a range of durable steel tubing
                  2x3", 1.25x3", and 1.625x3.125" steel finished in a texture
                  black powdercoat. The adjustable padded seat locks into 8
                  different height settings and 5 front-to-back settings, and
                  the 1.5” diameter rubber grip handles are welded directly to
                  the bike’s arms for added rigidity. A steel step plate is
                  provided along with knurled 4.5" long rotating foot pegs and
                  metal pedals. The fan itself is a belt-driven, precision steel
                  blade.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-quality.png"
                  alt="Rogue Echo Bike Quality Construction"
                />

                <h4>4. EASY ASSEMBLY + PORTABILITY</h4>
                <p>
                  Assembly is required to set-up the Echo Bike, but all
                  necessary tools and black hardware are included standard with
                  your order, along with easy step-by-step guidelines. While the
                  fully constructed bike is a beast, we’ve included a set of 1"
                  wide polyurethane wheels at the front of the unit, allowing it
                  to be rolled around the gym or stored out of the way with
                  ease.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-assembly.png"
                  alt="Rogue Echo Bike"
                />

                <h4>5. EXCLUSIVE ACCESSORIES & ATTACHMENTS</h4>
                <p>
                  Optional Echo Bike accessories can be included with any order
                  at an additional cost. This includes our mast-mounted Echo
                  Bike Phone Holder and the Echo Bike Wind Guard, a plastic
                  shield that prevents air from blowing back into the rider’s
                  face. The Echo Bike is also compatible with optional
                  accessories from other suppliers, including Shimano.
                </p>
                <img
                  src="/images/rogue-echo/features/echo-bike-accessories.png"
                  alt="Rogue Echo Bike"
                />

                <span id="n3"></span>
                <h2>Rogue Echo Bike Comparison</h2>
                <h3>
                  Rogue Echo Bike vs Assault AirBike vs Schwinn Airdyne Pro
                </h3>
                <p>
                  The Rogue Echo Bike is likely to be compared to the Assault
                  AirBike most of the time. However, the Rogue Echo Bike is more
                  closely related to the Schwinn Airdyne Pro since both air
                  bikes uses similar belt drive systems.
                </p>
                <p>
                  That being said, Schwinn has been manufacturing quality bikes
                  for over a century now and they’ve been a leading innovator in
                  air resistance technology. With the Airdyne AD Pro, Schwinn
                  has developed its most complete all-purpose stationary bike to
                  date—with a perimeter-weighted flywheel design, powerful solid
                  steel cranks, and doubled-coated steel body construction.
                  Multi-position handles allow the rider to use a vertical or
                  horizontal grip, redesigned foot platforms reduce slip and
                  improve push-off, and a larger, padded seat offers optimal
                  comfort. The universal rail and clamp system even lets users
                  swap in their own customized seats if they choose.
                </p>
                <img src="/images/airbike-prices.png" className="price-graph" alt="Air bike prices graph"/>
                <p>
                  On the other end, the Assault AirBike is a heavy-duty exercise
                  bike designed directly from the feedback of athletes and
                  coaches. The Assault AirBike reinvents and retools nearly
                  every component of the traditional fan bike, from the frame
                  construction to the crank, pedals, monitor and more. Keep in
                  mind this bike utilizes a chain driven system which will
                  produce much more noise when working the bike to peak
                  performance.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td className="table-bold">Brand:</td>
                      <td className="table-bold">Schwinn AD Pro</td>
                      <td className="table-bold">Assault AirBike</td>
                      <td className="table-bold">Rogue Echo Bike</td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>$1,299</td>
                      <td>$999</td>
                      <td>$795</td>
                    </tr>
                    <tr>
                      <td>Weight:</td>
                      <td>113 lbs.</td>
                      <td>98 lbs.</td>
                      <td>127 lbs.</td>
                    </tr>
                    <tr>
                      <td>Drive System:</td>
                      <td>Belt-Driven</td>
                      <td>Chain</td>
                      <td>Belt-Driven</td>
                    </tr>
                    <tr>
                      <td>Foot Pegs:</td>
                      <td>Rotating Plastic/Metal Pegs</td>
                      <td>Knurled Stationary Pegs</td>
                      <td>Rotating Metal Pegs</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td>Metal</td>
                      <td>Plastic</td>
                      <td>Metal</td>
                    </tr>
                    <tr>
                      <td>Monitor Presets:</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Adjustments:</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                    </tr>
                    <tr>
                      <td>Length:</td>
                      <td>42"</td>
                      <td>50.9"</td>
                      <td>58.875"</td>
                    </tr>
                    <tr>
                      <td>Width:</td>
                      <td>20"</td>
                      <td>23.3"</td>
                      <td>29.875"</td>
                    </tr>
                    <tr>
                      <td>Height:</td>
                      <td>52"</td>
                      <td>48.4"</td>
                      <td>52.75"</td>
                    </tr>
                  </tbody>
                </table>
                <span id="n4"></span>
                <h2>Rogue Echo Bike Rating</h2>
                <h3>
                  Rogue Echo Bike - <span className="score-rating">4.6</span>
                </h3>
                <StarRating
                  isHalfRating={true}
                  isReadOnly
                  initialRating={4.6}
                />
                <div className="rating__graphs" width="100%">
                  <div>
                    <p>Construction - 5.0</p>
                    <progress value="5" max="5"></progress>
                  </div>
                  <div>
                    <p>Durability 5.0</p>
                    <progress value="5" max="5"></progress>
                  </div>
                  <div>
                    <p>Performace - 4.8</p>
                    <progress value="4.8" max="5"></progress>
                  </div>
                  <div>
                    <p>Aesthetics - 4.5</p>
                    <progress value="4.5" max="5"></progress>
                  </div>
                  <div>
                    <p>Customization - 4</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Adjustability - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Value - 4.7</p>
                    <progress value="4.7" max="5"></progress>
                  </div>
                </div>
                <p>
                  The Rogue Echo Bike is a great choice for any garage or home
                  gym – especially for HIIT (High Intensity Interval Training)
                  workouts. It’s built like a tank, requires nearly zero
                  maintenance, it’s quiet (for an air bike), and it’s one of the
                  cheapest best value air bikes on the market.
                </p>
                <span id="n5"></span>
                <h2>Where to buy</h2>
                <div className="r-product-box">
                  <div className="product-box-brand">Rogue Echo Bike</div>
                  <div className="product-left">
                    <img
                      src="/images/rogue-echo/echo-bike-side.png"
                      alt="Rogue Echo Bike"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Rogue Echo Bike
                    </a>
                    <p>
                      The Rogue Echo Bike combines heavy-duty steel, precision
                      engineering, and convenient customization to forge a
                      stronger, sturdier fan bike.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReviewContainer>
        <RelatedBikes 
          bike1="Assault Airbike"
          bike1img="/images/assault-airbike.jpg"
          bikeLink1="/assault-airbike-review"

          bike2="Concept2 BikeErg"
          bike2img="/images/bike-erg.jpg"
          bikeLink2="/concept2-bike-erg-review"

          bike3="Schqinn AirDyne Pro"
          bike3img="/images/schwinn-airdyne-ad7.png"
          bikeLink3="/schwinn-airdyne-pro-review"
        />
      </Layout>
    )
  }
}

export default BikeReview